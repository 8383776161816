import styled from "styled-components";

const ContainerStoria = styled.div`
  margin-top: 100px;
  padding-right: 5rem;
  padding-left: 5rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const TestoStoria = styled.p`
  font-size: 18px;
  color: black;
  text-align: justify;
  font-family: "Work Sans", sans-serif;
  @media screen and (max-width: 768px) {
    font-size: 15px;
    max-width: 100%;
  }
`;

export { ContainerStoria, TestoStoria };
