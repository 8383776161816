import React from "react";
import {
  ContainerRestaAggiornato,
  ContainerTotalText,
  ContainerText,
  Title,
} from "./RestaAggiornatoStyled";
import News from "../../../assets/news.png";
import ProssimiConcerti from "../../../assets/Bottone, Prossimi concerti.png";
import { useMediaQuery } from "react-responsive";
const RestaAggiornato = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <ContainerRestaAggiornato style={{ marginTop: isMobile ? "2rem" : "" }}>
      <ContainerTotalText>
        <Title>Resta aggiornato</Title>
        <ContainerText>
          Partecipa ai futuri concerti e scopri gli eventi del Grande Coro...
        </ContainerText>
        <a href={"https://www.facebook.com/ilgrandecorodiroma/"}>
          <img src={News} width={"200px"} />
        </a>
        <a
          style={{ textDecoration: "none", color: "white" }}
          href={"/prossimi-concerti"}
        >
          <img src={ProssimiConcerti} width={"200px"} />
        </a>
      </ContainerTotalText>
    </ContainerRestaAggiornato>
  );
};

export default RestaAggiornato;
