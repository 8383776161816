import styled from "styled-components";

const NuovoLayout = styled.div`
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
`;

export { NuovoLayout };
