import styled from "styled-components";

const TitleBenvenuti = styled.div`
  font-size: 42px;
  color: #bc4749;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const TextBenvenuti = styled.p`
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  color: black;
  text-align: justify;
`;

const ContainerBenvenuti = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 400px;
  }
`;

export { TitleBenvenuti, TextBenvenuti, ContainerBenvenuti };
