import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { flexbox } from "@mui/system";
import { useMediaQuery } from "react-responsive";

const ModalFoto = ({ open, setOpen, imagesList }: any) => {
  const handleClose = () => setOpen(false);
  console.log(imagesList);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "550px",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        style: {
          backgroundColor: "#000000",
          opacity: "0.8",
        },
      }}
    >
      <Box sx={style}>
        <div>
          <Slide>
            {imagesList.length > 0 &&
              imagesList.map((image: any) => {
                return (
                  <div
                    style={{
                      width: isMobile ? "400px" : "100%",
                      height: isMobile ? "235px" : "360px",
                    }}
                  >
                    <div
                      style={{
                        width: isMobile ? "400px" : "100%",
                        height: isMobile ? "235px" : "100%",
                        backgroundImage: `url(${image})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                );
              })}
          </Slide>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalFoto;
