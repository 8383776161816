import styled from "styled-components";
const Sectionmenu = styled.div`
  color: white;
  height: 50px;
`;
const ContainerHeader = styled.div`
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: fixed;
  display: flex;
  flex-direction: row;
  background-color: #bc4749;
  top: 0;

  width: 100%;
  z-index: 1000000;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    height: 80px;
    align-content: center;
    top: -1.5rem;
  }
`;

const TitleSection = styled.div`
  font-family: "Kameron", serif;

  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100px;
  font-size: 19px;
  cursor: pointer;
  &:hover {
    background-color: #ce5157;
  }
  @media screen and (max-width: 768px) {
    font-size: 11px;
    width: 60px;
  }
`;

const LogoHeader = styled.img`
  max-height: 50px;
  @media screen and (max-width: 768px) {
    width: 100px;
    max-height: 100%;
    margin-top: 30px;
  }
`;

const RightMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    margin-top: 30px;
    align-items: center;
    gap: 0.25rem;
  }
`;

const ContainerMenu = styled.div`
  color: white;
  display: flex;
  gap: 1.5rem;

  flex-direction: column;
  position: absolute;
  top: 4rem;
  background-color: #ce5157;
  padding: 1rem;
  font-family: "Kameron", serif;
  font-size: 19px;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    font-size: 11px;
  }
`;

const ContainerMenu2 = styled.div`
  font-size: 19px;
  gap: 1.5rem;

  color: white;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4rem;
  right: 21rem;
  background-color: #ce5157;
  padding: 1rem;
  font-family: "Kameron", serif;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    font-size: 11px;
    top: 1000rem;
  }
`;

const ContainerMenu3 = styled.div`
  font-size: 19px;

  color: white;
  font-family: "Kameron", serif;
  gap: 1.5rem;

  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4rem;
  right: 7rem;
  background-color: #ce5157;
  padding: 1rem;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    font-size: 11px;
  }
`;
const ContainerMenu4 = styled.div`
  font-size: 19px;

  display: flex;
  flex-direction: column;
  position: absolute;
  top: 4rem;
  background-color: #ce5157;
  right: 1rem;
  color: white;
  padding: 1rem;
  font-family: "Kameron", serif;
  gap: 1.5rem;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    font-size: 11px;
  }
`;

export {
  ContainerHeader,
  ContainerMenu,
  LogoHeader,
  RightMenuHeader,
  TitleSection,
  ContainerMenu2,
  ContainerMenu3,
  ContainerMenu4,
};
