import React from "react";
import { Landing } from "../components/landing";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GalleriaFoto from "../components/galleriafoto/GalleriaFoto";
import Concerti from "../components/concerti/Concerti";
import { Storia } from "../components/storia";
import { DoveSiamo } from "../components/landing/dovesiamo";
import { ChiSiamo } from "../components/landing/chisiamo";
import { Contatti } from "../components/contatti";
import { ArchivioConcerti } from "../components/landing/archivioconcerti";
import { Dashboard } from "../components/dashboard";

const ProviderRoute = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/galleria-foto" element={<GalleriaFoto />} />
        <Route path="/galleria-concerti" element={<ArchivioConcerti />} />
        <Route path="/prossimi-concerti" element={<Concerti />} />
        <Route path="/storia" element={<Storia />} />
        <Route path="/dove-siamo" element={<DoveSiamo />} />
        <Route path="/chi-siamo" element={<ChiSiamo />} />
        <Route path="/contatti" element={<Contatti />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

export default ProviderRoute;
