import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  ContainerBenvenuti,
  TextBenvenuti,
  TitleBenvenuti,
} from "./BenvenutiStyle";

const Benvenuti = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <ContainerBenvenuti style={{ marginTop: isMobile ? "5rem" : "" }}>
      <TitleBenvenuti>Benvenuti!</TitleBenvenuti>
      <TextBenvenuti style={{ maxWidth: isMobile ? "300px" : "" }}>
        Il Grande Coro di Roma è formato da Cantanti non professionisti uniti
        dalla passione per la musica ed il canto che hanno come fine quello di
        sostenere, con entusiasmo ed impegno, iniziative di solidarietà e di
        giustizia sociale tramite il volontariato. Il Coro è accompagnato al
        piano, durante i concerti, da strumentisti e si avvale anche della
        collaborazione di Solisti di levatura internazionale.
      </TextBenvenuti>
    </ContainerBenvenuti>
  );
};

export default Benvenuti;
