import React, { useState } from "react";
import {
  ContainerHeader,
  LogoHeader,
  RightMenuHeader,
  TitleSection,
  ContainerMenu,
  ContainerMenu2,
  ContainerMenu3,
  ContainerMenu4,
} from "./HeaderStyle";
import Logo from "../../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [lanostrastoria, setLanostrastoria] = useState(false);
  const [galleria, setGalleria] = useState(false);
  const [archivioconc, setArchivioconc] = useState(false);
  const [contatt, setContatt] = useState(false);
  return (
    <ContainerHeader>
      <a href={"/"}>
        <LogoHeader src={Logo} alt={"logo"} />
      </a>
      <RightMenuHeader>
        <div>
          <TitleSection
            onClick={() => {
              setGalleria(false);
              setContatt(false);
              setArchivioconc(false);
              setLanostrastoria(!lanostrastoria);
            }}
          >
            Home
          </TitleSection>
          {lanostrastoria && (
            <ContainerMenu
              style={{
                right: isMobile ? "10rem" : "",
                top: isMobile ? "5rem" : "",
              }}
            >
              <a
                style={{ textDecoration: "none", color: "white" }}
                href={"/storia"}
              >
                La nostra storia
              </a>
              <a
                href="/chi-siamo"
                style={{ textDecoration: "none", color: "white" }}
              >
                Il Coro
              </a>
            </ContainerMenu>
          )}
        </div>
        <TitleSection
          onClick={() => {
            setGalleria(!galleria);
            setContatt(false);
            setArchivioconc(false);
            setLanostrastoria(false);
          }}
        >
          Gallery
        </TitleSection>
        {galleria && (
          <ContainerMenu2
            style={{
              right: isMobile ? "9.5rem" : "",
              top: isMobile ? "5rem" : "",
            }}
          >
            <a
              style={{ textDecoration: "none", color: "white" }}
              href={"/galleria-foto"}
            >
              Foto
            </a>
            <a
              href={"https://www.youtube.com/channel/UCecpJXZCCNSO6-VK6W4cLTA/"}
              style={{ textDecoration: "none", color: "white" }}
            >
              Video
            </a>
          </ContainerMenu2>
        )}
        <TitleSection
          onClick={() => {
            setGalleria(false);
            setContatt(false);
            setArchivioconc(!archivioconc);
            setLanostrastoria(false);
          }}
        >
          Concerti
        </TitleSection>
        {archivioconc && (
          <ContainerMenu3
            style={{
              right: isMobile ? "3rem" : "",
              top: isMobile ? "5rem" : "",
            }}
          >
            <a
              href={"/galleria-concerti"}
              style={{ textDecoration: "none", color: "white" }}
            >
              Archivio Concerti
            </a>
            <a
              href={"/prossimi-concerti"}
              style={{ textDecoration: "none", color: "white" }}
            >
              Prossimi Concerti
            </a>
          </ContainerMenu3>
        )}
        <a
          href={"/contatti"}
          style={{ textDecoration: "none", color: "white" }}
        >
          <TitleSection
            onClick={() => {
              setGalleria(false);
              setContatt(false);
              setArchivioconc(false);
              setLanostrastoria(false);
            }}
          >
            Contatti
          </TitleSection>
        </a>
      </RightMenuHeader>
    </ContainerHeader>
  );
};

export default Header;
