import styled from "styled-components";

const FirstContainerFooter = styled.div`
  position: relative;
  bottom: -1rem;
  display: flex;
  flex-direction: column;
  background-color: #eae9e1;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  line-height: 5px;
`;

const SecondContainerFooter = styled.div`
  position: relative;
  bottom: -1rem;

  font-family: "Kameron", serif;
  background-color: #bc4749;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const TitleFooter = styled.p`
  font-size: 23px;
  color: #bc4749;
  font-family: "Kameron", serif; ;
`;

const TextFooter = styled.p`
  font-size: 18px;
  font-family: "Kameron", serif; ;
`;

const SubtitleFooter = styled.p`
  font-size: 16px;
  color: white;
  font-family: "Kameron", serif;
  @media screen and (max-width: 768px) {
    font-size: 9px;
  }
`;
export {
  FirstContainerFooter,
  SubtitleFooter,
  TextFooter,
  TitleFooter,
  SecondContainerFooter,
};
