import React from "react";
import { Landing } from "./components/landing";
import { Header } from "./components/header";
import { createGlobalStyle } from "styled-components";
import { Footer } from "./components/footer";
import ProviderRoute from "./route/Route";

function App() {
  const GlobalStyle = createGlobalStyle`
  body {
  
      margin-left: auto;
      margin-right: auto;
      
    }
  p{
    font-family: 'Oranienbaum', serif; ;
  }
   *{
     box-sizing: border-box;
   }
   a{
     text-decoration:none;
   }
   span{
    text-decoration:none;
   }
`;
  return (
    <>
      <GlobalStyle />
      <Header />

      <ProviderRoute />
      <Footer />
    </>
  );
}

export default App;
