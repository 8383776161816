import React, { useRef, useState } from "react";
import { TitleBenvenuti } from "../landing/home/benvenuti/BenvenutiStyle";
import { ContainerDashboard } from "./DashboardStyled";
import { createClient } from "@supabase/supabase-js";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

const SUPABASE_URL = "https://arkmohlsxoogcmubsgwx.supabase.co";
const SUPBASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFya21vaGxzeG9vZ2NtdWJzZ3d4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjI1MDE3NDcsImV4cCI6MTk3ODA3Nzc0N30.aFubQavqChgWSWNAuFownNMALCwadRG3luha9dsHGe0";

const client = createClient(SUPABASE_URL, SUPBASE_ANON_KEY);
const Dashboard = () => {
  const aggiornaConcerto = async () => {
    const prova = await client.from("prossimo").upsert({
      titolo: titolo2,
      descrizione: descrizione2,
      indirizzo: indirizzo2,
    });
    if (secondo.current !== null) secondo.current.reset();
  };
  const salvaConcerto = async () => {
    const prova = await client.from("concerti").insert({
      titolo: titolo,
      descrizione: descrizione,
      indirizzo: indirizzo,
    });

    setTitolo(undefined);
    setIndirizzo(undefined);
    setDescrizione(undefined);
    if (primo.current !== null) primo.current.reset();
  };
  const primo = useRef<HTMLFormElement>(null);
  const secondo = useRef<HTMLFormElement>(null);
  const [titolo, setTitolo] = useState();
  const [indirizzo, setIndirizzo] = useState();
  const [descrizione, setDescrizione] = useState();

  const [titolo2, setTitolo2] = useState();
  const [indirizzo2, setIndirizzo2] = useState();
  const [descrizione2, setDescrizione2] = useState();

  return (
    <ContainerDashboard style={{ maxWidth: "60%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TitleBenvenuti>Aggiungi Concerto archivio</TitleBenvenuti>
        <form
          id="primo"
          ref={primo}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <input
            onChange={(e: any) => setTitolo(e.target.value)}
            title="titolo"
            placeholder="titolo"
          ></input>
          <input
            onChange={(e: any) => setDescrizione(e.target.value)}
            title="descrizione"
            placeholder="descrizione"
          ></input>
          <input
            onChange={(e: any) => setIndirizzo(e.target.value)}
            title="indirizzo foto"
            placeholder="indirizzo foto"
          ></input>
        </form>

        <button onClick={() => salvaConcerto()}>Conferma</button>
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <TitleBenvenuti>Aggiorna Prossimo Concerto</TitleBenvenuti>
        <form
          ref={secondo}
          id="secondo"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <input
            onChange={(e: any) => setTitolo2(e.target.value)}
            title="titolo"
            placeholder="titolo"
          ></input>
          <input
            onChange={(e: any) => setDescrizione2(e.target.value)}
            title="descrizione"
            placeholder="descrizione"
          ></input>
          <input
            onChange={(e: any) => setIndirizzo2(e.target.value)}
            title="indirizzo foto"
            placeholder="indirizzo foto"
          ></input>
        </form>

        <button onClick={() => aggiornaConcerto()}>Conferma</button>
      </div>
    </ContainerDashboard>
  );
};

export default Dashboard;
