import styled from "styled-components";
import HomeFoto from "../../../assets/fotohome.jpg";

const ContainerHome = styled.div`
  z-index: -100;
  width: 100%;
  height: 1000px;
  position: relative;
  background: url(${HomeFoto});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  opacity: 0.75;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

const Opacity = styled.div`
  background-color: rgba(206, 81, 87, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export { Opacity, ContainerHome };
