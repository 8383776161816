import styled from "styled-components";

const ContainerRestaAggiornato = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    align-items: center;
  }
`;
const ContainerTotalText = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 400px;
  }
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 80%;
    padding: 2rem;
  }
`;

const Title = styled.p`
  font-size: 38px;
  color: #ce5157;
`;

const RestaAggiornatoText = styled.p`
  font-size: 18px;
  color: black;
`;

export {
  RestaAggiornatoText,
  ContainerTotalText,
  ContainerText,
  ContainerRestaAggiornato,
  Title,
};
