import styled from "styled-components";

const ContainerConcert = styled.div`
  position: relative;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-top: 5rem;
    max-width: 100%;
  }
`;

const ContainerConcert2 = styled.div`
  position: relative;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;
  justify-content: center;
`;

const ContainerConcerto = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  gap: 2rem;
  min-height: 300px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const ContainerImmagine = styled.div`
  width: 400px;
  max-height: 500px;
  background-color: pink;
`;

const ContainerText = styled.div`
  position: relative;
  width: 500px;
`;

const ContainerTitle = styled.div`
  top: 1rem;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    margin-bottom: 4rem;
  }
`;
const TitleConcerto = styled.p`
  font-size: 30px;
  font-weight: 700;
  color: #ce5157;
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;
const TextConcerto = styled.p`
  font-size: 20px;
`;

export {
  TextConcerto,
  TitleConcerto,
  ContainerTitle,
  ContainerText,
  ContainerImmagine,
  ContainerConcerto,
  ContainerConcert,
  ContainerConcert2,
};
