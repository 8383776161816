import React from "react";
import { ContainerConcert } from "../../concerti/ConcertiStyled";
import { TitleBenvenuti } from "../home/benvenuti/BenvenutiStyle";
import Mock from "../../../assets/immagineCoro.png";
import Mock2 from "../../../assets/immagineCoro2.png";
import AB from "../../../assets/AB.jpg";
import GC from "../../../assets/GC.jpg";
import GL from "../../../assets/GL.jpg";
import MS from "../../../assets/MS.jpg";
import TB from "../../../assets/TB.jpg";

import { mockComponent } from "react-dom/test-utils";
import { TestoStoria } from "../../storia/StoriaStyled";
import { SoloMobile } from "./ChiSiamoStyled";
import { useMediaQuery } from "react-responsive";

const coristi = [
  {
    nome: "Gabriele Leibfritz",
    ruolo: "Presidente",
    img: GL,
  },
  {
    nome: "Tiziana Bartoloni",
    ruolo: "Vice-Presidente",
    img: TB,
  },
  {
    nome: "Antonella Alberighi",
    ruolo: "Segretario",
    img: AB,
  },
  {
    nome: "Marina Saraceni",
    ruolo: "Tesoriere",
    img: MS,
  },
  {
    nome: "Giuseppina Colarusso",
    ruolo: "Membro",
    img: GC,
  },
];
const ChiSiamo = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  return (
    <ContainerConcert>
      <TitleBenvenuti>Il Coro</TitleBenvenuti>
      <TitleBenvenuti style={{ fontSize: "22px" }}>
        Consiglio d'Amministrazione
      </TitleBenvenuti>
      <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <SoloMobile>
          {coristi.map((x) => {
            return (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <TitleBenvenuti
                  style={{
                    fontSize: isMobile ? "18px" : "20px",
                    marginTop: isMobile ? "0.5rem" : "0.5rem",
                    marginBottom: "0.75rem",
                  }}
                >
                  {x.ruolo}
                </TitleBenvenuti>
                <img src={x.img} style={{ width: "150px" }} alt="mock" />
                <p
                  style={{
                    position: "absolute",
                    bottom: "-0.50rem",
                    color: "white",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {x.nome.toUpperCase()}
                </p>
              </div>
            );
          })}
        </SoloMobile>
      </div>
      <TitleBenvenuti>Il Repertorio</TitleBenvenuti>
      <TitleBenvenuti style={{ fontSize: "22px", textAlign: "left" }}>
        Musica profana (musiche da film, musical e trascrizioni operistiche)
      </TitleBenvenuti>
      <TestoStoria style={{ width: "800px", marginTop: "-1.5rem" }}>
        - <span style={{ fontWeight: "bold" }}>CLIMB EVERY MOUNTAIN</span> di
        Rodgers/Hammerstein dal musical The Sound of Music <br />-{" "}
        <span style={{ fontWeight: "bold" }}>SOMEWHERE OVER THE RAINBOW </span>
        di Harburg/Arlen dal musical The Wizard of Oz <br />-{" "}
        <span style={{ fontWeight: "bold" }}>
          I COULD HAVE DANCED ALL NIGHT
        </span>{" "}
        di Lerner/Loewe dal musical My Fair Lady <br />-{" "}
        <span style={{ fontWeight: "bold" }}>LA’ CI DAREM LA MANO</span> di Da
        Ponte/Mozart dall’opera Don Giovanni
        <br />- <span style={{ fontWeight: "bold" }}>VA, PENSIERO</span> di
        Solera/Verdi dall’opera Nabucco
        <br />- <span style={{ fontWeight: "bold" }}>WHEN YOU BELIEVE</span> di
        Schwartz dal film Il Principe d’Egitto <br />-{" "}
        <span style={{ fontWeight: "bold" }}>THE BARE NECESSITIES</span>{" "}
        di Gilkyson dal film Il Libro della giungla
        <br />- <span style={{ fontWeight: "bold" }}>SOMEWHERE</span>
         di Sondheim/Bernstein dal musical West Side Story
        <br />- <span style={{ fontWeight: "bold" }}>
          SOMEWHERE, MY LOVE
        </span>{" "}
        di Webster/Jarre dal film Doctor Zhivago
        <br />-{" "}
        <span style={{ fontWeight: "bold" }}>AMERICA THE BEAUTIFUL</span> di
        Bates/Dragon/Ward Tradizionale americana
        <br />-
        <span style={{ fontWeight: "bold", marginLeft: "-0.20rem" }}>
          {" "}
          LOVE IS A MANY SPLENDORED THING{" "}
        </span>{" "}
        di Webster/Fain dal film L’Amore è una cosa meravigliosa
        <br />- <span style={{ fontWeight: "bold" }}>LOVE SAID GOODBYE</span> di
        Rota/Kusik dal film Il Padrino-parte
        <br />-{" "}
        <span style={{ fontWeight: "bold" }}>II CLIMB EVERY MOUNTAIN</span> di
        Hammerstain II/Rodgers dal musical The Sound of Music <br />-{" "}
        <span style={{ fontWeight: "bold" }}>NESSUN DORMA</span> di
        Adami/Simoni/Puccini dall’opera Turandot
        <br />- <span style={{ fontWeight: "bold" }}>NEW YORK NEW YORK</span> di
        Fred Ebb, John Kander dal film omonimo <br />-{" "}
        <span style={{ fontWeight: "bold" }}>RADETZKY MARCH</span> di
        Geehl/Strauss <br />-{" "}
        <span style={{ fontWeight: "bold" }}>'O SOLE MIO </span>di
        Capurro/Perna/Di Capua Tradizionale napoletana
        <br />- <span style={{ fontWeight: "bold" }}>
          I KNEW I LOVED YOU
        </span>{" "}
        di Bergman/Morricone dal film C'era una volta in America <br />-{" "}
        <span style={{ fontWeight: "bold" }}>HABANERA</span> di
        Meilhac/Halévy/Bizet dall'opera Carmen <br />-{" "}
        <span style={{ fontWeight: "bold" }}>ONE DAY MORE</span> di
        Kretzmer/Schönberg dal musical Les Misérables
        <br />- <span style={{ fontWeight: "bold" }}>BOLERO</span> di
        Robles/Ravel
        <br />-{" "}
        <span style={{ fontWeight: "bold" }}>
          IT’S A GRAND NIGHT FOR SINGING
        </span>{" "}
        di Hammerstein/Rodgers dal musical State Fair <br />-{" "}
        <span style={{ fontWeight: "bold" }}>THE RAIN IN SPAIN </span>di
        Lerner/Loewe dal musical My Fair Lady <br />-{" "}
        <span style={{ fontWeight: "bold" }}>PEOPLE</span> di Merrill/Styne dal
        musical Funny Girl
        <br />- <span style={{ fontWeight: "bold" }}>
          BEAUTY AND THE BEAST
        </span>{" "}
        di Menker/Ashman dal film La Bella e la Bestia <br />-{" "}
        <span style={{ fontWeight: "bold" }}>DON’T CRY FOR ME ARGENTINA</span>{" "}
        di Webber/Rice dal musical Evita
        <br />-{" "}
        <span style={{ fontWeight: "bold" }}>DRY YOUR TEARS, AFRIKA</span> di
        Williams dal film Amistad <br />-{" "}
        <span style={{ fontWeight: "bold" }}>ODE AN DIE FREUDE</span> di
        Beethoven/von Schiller Inno alla Gioia
        <br />- <span style={{ fontWeight: "bold" }}>INNO DI MAMELI</span> di
        Mameli/Novaro Inno nazionale Italiano
        <br />-{" "}
        <span style={{ fontWeight: "bold" }}>
          ON THE STREET WHERE YOU LIVE
        </span>{" "}
        di Lerner/Loewe dal musical My Fair Lady
        <br />-{" "}
        <span style={{ fontWeight: "bold" }}>
          {" "}
          ROMA, NUN FA’ LA STUPIDA STASERA
        </span>{" "}
        di Giovannini/Trovajoli dal musical Rugantino
        <br />-{" "}
        <span style={{ fontWeight: "bold" }}>THE STAR SPANGLED-BANNER </span>di
        Stafford Smith/Hofmann Inno Americano <br />-{" "}
        <span style={{ fontWeight: "bold" }}>SCHINDLER’S LIST </span> di William
        dal film omonimo
        <br />- <span style={{ fontWeight: "bold" }}>
          E LUCEVAN LE STELLE{" "}
        </span>{" "}
        di Illica/Giacosa/Puccini dall’opera Tosca
        <br /> - <span style={{ fontWeight: "bold" }}>MASQUERADE</span> di Lloyd
        Webber dal film Il Fantasma dell'Opera
        <br /> -{" "}
        <span style={{ fontWeight: "bold" }}>
          THE GREEN LEAVES OF SUMMER
        </span>{" "}
        di Webster/Tiomkin dal film Alamo
        <br /> - <span style={{ fontWeight: "bold" }}>AMERICA</span> di
        Sonheim/Berstein dal film West Side Story
        <br /> - <span style={{ fontWeight: "bold" }}>
          LASCIA CH'IO PIANGA
        </span>{" "}
        di Rossi/Handel
      </TestoStoria>
      <TitleBenvenuti style={{ fontSize: "22px", textAlign: "left" }}>
        Musica sacra e operistica
      </TitleBenvenuti>
      <TestoStoria style={{ width: "800px", marginTop: "-1.5rem" }}>
        - <span style={{ fontWeight: "bold" }}>GLORIA</span> di Vivaldi 12
        Movimenti <br />-{" "}
        <span style={{ fontWeight: "bold" }}>AVE VERUM CORPUS</span> di Mozart
        <br />- <span style={{ fontWeight: "bold" }}>LAUDATE DOMINUM</span> di
        Mozart dai Vesperae solemnes de confessore <br />-{" "}
        <span style={{ fontWeight: "bold" }}>CASTA DIVA</span> di Bellini dalla
        Norma <br />- <span style={{ fontWeight: "bold" }}>GERUSALEMME</span> di
        Verdi dal Nabucco Part
        <br />-{" "}
        <span style={{ fontWeight: "bold" }}>I CORO DEGLI SCHIAVI EBREI </span>
        di Verdi dal Nabucco <br />-{" "}
        <span style={{ fontWeight: "bold" }}>CORO DEGLI ZINGARI</span> di Verdi
        da Il Trovatore
        <br />- <span style={{ fontWeight: "bold" }}>
          CORO DI ZINGARELLE{" "}
        </span>{" "}
        di Verdi da La Traviata
        <br />-{" "}
        <span style={{ fontWeight: "bold" }}>CORO DI MATTADORI SPAGNUOLI </span>
        di Verdi
        <br />- <span style={{ fontWeight: "bold" }}>BRINDISI </span>di Verdi da
        La Traviata
        <br />- <span style={{ fontWeight: "bold" }}> AN DIE FREUDE </span>di
        Beethoven Sinfonia n.9
        <br />
      </TestoStoria>
    </ContainerConcert>
  );
};

export default ChiSiamo;
