import React from "react";
import { ContainerHome, Opacity } from "./HomeStyle";
import BottonFB from "../../../assets/BottonFB.png";
import { useMediaQuery } from "react-responsive";
const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      <ContainerHome>
        <Opacity />
        <p
          style={{
            zIndex: 10,
            color: "#fcfcfc",
            fontSize: isMobile ? "50px" : "150px",
            fontWeight: "500",
            position: "absolute",
            top: isMobile ? "7rem" : "5rem",
            textAlign: "center",
            fontFamily: "Kameron",
          }}
        >
          il Grande Coro <br />
          di Roma
        </p>
      </ContainerHome>
    </>
  );
};

export default Home;
