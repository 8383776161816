import styled from "styled-components";

const ContainerStoria = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
`;

const ContainerStoria2 = styled.div`
  height: 500px;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 400px;
    padding: 3rem;
    height: auto;
    align-items: center;
    align-content: center;
  }
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContainerTotalText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const Title = styled.p`
  font-size: 38px;
  color: #ce5157;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const StoryText = styled.p`
  text-align: justify;
  font-size: 18px;
  color: black;
  font-family: "Work Sans", sans-serif;
`;

export {
  ContainerText,
  ContainerTotalText,
  ContainerStoria,
  Title,
  StoryText,
  ContainerStoria2,
};
