import styled from "styled-components";

const ContainerGalleria = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-top: 100px;
  @media screen and (max-width: 768px) {
    margin-top: 6rem;
  }
`;

const GridContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const GalleryContainer = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
`;

const TextConcerto = styled.p`
  font-size: 28px;
  color: white;
  font-weight: 700;
`;

const SecondTextConcerto = styled.span`
  font-size: 22px;
  color: white;
`;

const TitleResta = styled.p`
  font-size: 32px;
  color: #bc4749;
  font-weight: 700;
`;

const ContainerTotalText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainterConcertoDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 1rem;
  width: 33%;
  left: 1rem;
`;
export {
  ContainerGalleria,
  GridContainer,
  GalleryContainer,
  TextConcerto,
  SecondTextConcerto,
  ContainterConcertoDiv,
  ContainerTotalText,
  TitleResta,
  ContainerText,
};
