import React from "react";
import { ContainerStoria, TestoStoria } from "./StoriaStyled";
import {
  TextBenvenuti,
  TitleBenvenuti,
} from "../landing/home/benvenuti/BenvenutiStyle";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import uno from "../../assets/slideshow/1.jpg";
import due from "../../assets/slideshow/2.jpg";
import tre from "../../assets/slideshow/3.jpg";
import quattro from "../../assets/slideshow/4.jpg";
import cinque from "../../assets/slideshow/5.jpg";
import { useMediaQuery } from "react-responsive";

const Storia = () => {
  const proprietaslide = {
    duration: 4000,
    infinite: true,
    prevArrow: <div style={{ width: "30px", marginRight: "-30px" }}></div>,
    nextArrow: <div style={{ width: "30px", marginLeft: "-30px" }}></div>,
    indicators: true,
  };
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  return (
    <ContainerStoria style={{ marginTop: isMobile ? "6rem" : "" }}>
      <TitleBenvenuti>La nostra storia</TitleBenvenuti>
      <div style={{ marginTop: "2rem", marginBottom: "1rem" }}>
        <Slide {...proprietaslide}>
          <img src={uno} width={"100%"} />
          <img src={due} width={"100%"} />
          <img src={tre} width={"100%"} />
          <img src={quattro} width={"100%"} />
          <img src={cinque} width={"100%"} />
        </Slide>
      </div>

      <TestoStoria>
        L’associazione musicale "Il Grande Coro di Roma" (iGCR) è stata fondata
        nel 2015 da un gruppo di cantanti non professionisti provenienti da
        diverse realtà romane e del Lazio, uniti dalla passione per la musica ed
        il canto corale.
        <br />
        Precedentemente alla sua fondazione, già dal 2010 il Coro si esprime in
        concerti che hanno come fine quello di sostenere, con il proprio
        impegno, iniziative di solidarietà e volontariato promuovendo la
        diffusione della musica polifonica-corale.
        <br />
        Nel corso della sua storia l’iGCR ha riunito non soltanto cantanti dai
        vari CRAL di Roma (RAI, FAO, UNWFP, INAIL, TIM, INPS, MiBACT, Regione
        Lazio fra i principali) ma anche gruppi corali dei Castelli Romani e
        dipendenti dell’ESA (Agenzia Spaziale Europea) conferendo al coro,
        insieme ai dipendenti della FAO e del WFP, un tratto tipicamente
        internazionale.
        <br />
        L’iGCR è stato invitato da diverse organizzazioni e istituzioni come
        l’ONU, Emergency, il Centro Regionale Sant’Alessio per i Ciechi, la
        Caritas, la Croce Rossa italiana, l’Ospedale Bambino Gesù, la Fondazione
        Santa Lucia, l’Università Campus Bio-Medico, la Casa circondariale di
        Rebibbia, la Regione Lazio, la Comunità di Sant’Egidio, ed il Centro di
        Riabilitazione Villaggio Litta fra le molte.
        <br />
        Numerose sono state le onlus territoriali con cui l’iGCR ha collaborato
        e per le quali si è esibito (Associazione Bambino Emopatico, ADA España,
        Ass. italiana per la Sclerosi multipla, A.M.I.C.I Lazio onlus, Amistad,
        AMREI, Antea, Dynamo Camp, Fratelli Maristi , GuardaLontano, La Stella
        di Lorenzo, Mais, Peter Pan, Sanes, Un Canto per Maja, Il Ponte, ARCO92,
        e molte altre.
      </TestoStoria>
    </ContainerStoria>
  );
};

export default Storia;
