import styled from "styled-components";

const ContainerContatti = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 3rem;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-top: 1rem;
  }
`;

export { ContainerContatti };
