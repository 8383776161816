import React, { useEffect, useState } from "react";
import {
  ContainerGalleria,
  ContainterConcertoDiv,
  GalleryContainer,
  GridContainer,
  SecondTextConcerto,
  TextConcerto,
  TitleResta,
  ContainerTotalText,
  ContainerText,
} from "./GalleriaFotoStyled";
import {
  TextBenvenuti,
  TitleBenvenuti,
} from "../landing/home/benvenuti/BenvenutiStyle";
import { Title } from "../landing/story/StoryStyled";
import News from "../../assets/news.png";
import ProssimiConcerti from "../../assets/Bottone, Prossimi concerti.png";
import { RestaAggiornato } from "../landing/restaaggiornato";
import {
  uno as TeleUno,
  due as TeleDue,
  tre as TeleTre,
  quattro as TeleQuattro,
  cinque as TeleCinque,
  sei as TeleSei,
} from "../../assets/telethon";
import { uno as FaoUno, due as FaoDue, tre as FaoTre } from "../../assets/fao";
import {
  uno as SeUno,
  due as SeDue,
  tre as SeTre,
  quattro as SeQuattro,
  cinque as SeCinque,
  sei as SeSei,
} from "../../assets/seraphicum";
import {
  uno as LUno,
  due as LDue,
  tre as LTre,
  quattro as LQuattro,
  cinque as LCinque,
} from "../../assets/luiss";

import {
  uno as FUno,
  due as FDue,
  tre as FTre,
  quattro as FQuattro,
  cinque as FCinque,
} from "../../assets/farfa";

import {
  uno as PallaUno,
  due as PallaDue,
  tre as PallaTre,
  quattro as PallaQuattro,
  cinque as PallaCinque,
  sei as PallaSei,
} from "../../assets/palladium";

import {
  uno as PopoUno,
  due as PopoDue,
  tre as PopoTre,
  quattro as PopoQuattro,
  cinque as PopoCinque,
} from "../../assets/popolari";

import {
  uno as TUno,
  due as TDue,
  tre as TTre,
  quattro as TQuattro,
  cinque as TCinque,
} from "../../assets/telethon2";

import {
  uno as SCUno,
  due as SCDue,
  tre as SCTre,
  quattro as SCQuattro,
  cinque as SCCinque,
} from "../../assets/santachiara";

import {
  uno as FFUno,
  due as FFDue,
  tre as FFTre,
  quattro as FFQuattro,
  cinque as FFCinque,
  sei as FFSei,
} from "../../assets/fao2";
import {
  uno as AUno,
  due as ADue,
  tre as ATre,
  quattro as AQuattro,
  cinque as ACinque,
} from "../../assets/auditorium";

import {
  uno as FFFUno,
  due as FFFDue,
  tre as FFFTre,
  quattro as FFFQuattro,
  cinque as FFFCinque,
  sei as FFFSei,
} from "../../assets/fao3";
import {
  uno as MattaUno,
  due as MattaDue,
  tre as MattaTre,
  quattro as MattaQuattro,
  cinque as MattaCinque,
  sei as MattaSei,
} from "../../assets/mattarella";
import {
  uno as AnteaUno,
  due as AnteaDue,
  tre as AnteaTre,
  quattro as AnteaQuattro,
  cinque as AnteaCinque,
} from "../../assets/anteagonfalone";

import {
  uno as PUno,
  due as PDue,
  tre as PTre,
  quattro as PQuattro,
  cinque as PCinque,
} from "../../assets/ponte";

import {
  uno as ComUno,
  due as ComDue,
  tre as ComTre,
  quattro as ComQuattro,
  cinque as ComCinque,
} from "../../assets/comunita";

import {
  uno as AuUno,
  due as AuDue,
  tre as AuTre,
  quattro as AuQuattro,
  cinque as AuCinque,
} from "../../assets/autismo";

import {
  uno as AmiUno,
  due as AmiDue,
  tre as AmiTre,
  quattro as AmiQuattro,
} from "../../assets/amistad";

import {
  uno as PaUno,
  due as PaDue,
  tre as PaTre,
  quattro as PaQuattro,
  cinque as PaCinque,
  sei as PaSei,
} from "../../assets/parcomusica";
import {
  uno as SUno,
  due as SDue,
  tre as STre,
  quattro as SQuattro,
  cinque as SCinque,
  sei as SSei,
} from "../../assets/santacecilia";
import {
  uno as LuceUno,
  due as LuceDue,
  tre as LuceTre,
  quattro as LuceQuattro,
  cinque as LuceCinque,
  sei as LuceSei,
} from "../../assets/festaluce";

import {
  uno as ArUno,
  due as ArDue,
  tre as ArTre,
  quattro as ArQuattro,
  cinque as ArCinque,
} from "../../assets/arcoclub";

import {
  uno as MonteUno,
  due as MonteDue,
  tre as MonteTre,
  quattro as MonteQuattro,
  cinque as MonteCinque,
  sei as MonteSei,
} from "../../assets/montecitorio";

import {
  uno as RavUno,
  due as RavDue,
  tre as RavTre,
  quattro as RavQuattro,
  cinque as RavCinque,
  sei as RavSei,
} from "../../assets/ravelo";
import {
  uno as PariUno,
  due as PariDue,
  tre as PariTre,
  quattro as PariQuattro,
  cinque as PariCinque,
  sei as PariSei,
} from "../../assets/parioli";

import {
  uno as AppiaUno,
  due as AppiaDue,
  tre as AppiaTre,
} from "../../assets/appia";
import {
  uno as MusaUno,
  due as MusaDue,
  tre as MusaTre,
  quattro as MusaQuattro,
} from "../../assets/musa";

import ModalFoto from "./ModalFoto";

const GalleriaFoto = () => {
  const [open, setOpen] = useState(false);
  const [lista, setLista] = useState<String[]>([]);

  useEffect(() => {
    console.log(typeof lista);
    console.log(lista.length);
    if (lista.length > 0) {
      setOpen(true);
    }
  }, [lista]);

  return (
    <ContainerGalleria>
      <TitleBenvenuti>Galleria Foto</TitleBenvenuti>
      <div style={{ padding: "3rem" }}>
        <TextBenvenuti>
          Attraverso alcuni scatti potrai rivivere gli eventi più importanti del
          coro; le emozioni dei coristi e le reazioni del pubblico:
        </TextBenvenuti>
      </div>
      <GridContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${FaoUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([FaoUno, FaoDue, FaoTre]);
          }}
        >
          <ContainterConcertoDiv>
            <TextConcerto>FAO</TextConcerto>
            <SecondTextConcerto>22/03/2013</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)), url(${SeUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([SeUno, SeDue, SeTre, SeQuattro, SeCinque, SeSei]);
          }}
        >
          <ContainterConcertoDiv>
            <TextConcerto>Seraphicum</TextConcerto>
            <SecondTextConcerto>08/12/2013</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: ` linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${AnteaUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([AnteaUno, AnteaDue, AnteaTre, AnteaQuattro, AnteaCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Antea Gonfalone</TextConcerto>
            <SecondTextConcerto>01/12/2014</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${TeleUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([
              TeleUno,
              TeleDue,
              TeleTre,
              TeleQuattro,
              TeleCinque,
              TeleSei,
            ]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Telethon RAI UNO</TextConcerto>
            <SecondTextConcerto>13/12/2014</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>{" "}
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${PaUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([PaUno, PaDue, PaTre, PaQuattro, PaCinque, PaSei]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Parco della musica</TextConcerto>
            <SecondTextConcerto>04/01/2014</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${AmiUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([AmiUno, AmiDue, AmiTre, AmiQuattro]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Amistad Sant Paul</TextConcerto>
            <SecondTextConcerto>14/02/2015</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${SUno}) `,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([SUno, SDue, STre, SQuattro, SCinque, SSei]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Santa Cecilia Conservatorio</TextConcerto>
            <SecondTextConcerto>18/04/2015</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${MattaUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([
              MattaUno,
              MattaDue,
              MattaTre,
              MattaQuattro,
              MattaCinque,
              MattaSei,
            ]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>INAIL Mattarella</TextConcerto>
            <SecondTextConcerto>01/05/2015</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${FFUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([FFUno, FFDue, FFTre, FFQuattro, FFCinque, FFSei]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>FAO</TextConcerto>
            <SecondTextConcerto>25/09/2016</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${LuceUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([
              LuceUno,
              LuceDue,
              LuceTre,
              LuceQuattro,
              LuceCinque,
              LuceSei,
            ]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Festa della Luce </TextConcerto>
            <SecondTextConcerto>13/12/2015</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${MusaUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([MusaUno, MusaDue, MusaTre, MusaQuattro]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Musa</TextConcerto>
            <SecondTextConcerto>18/12/2015</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${AUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([AUno, ADue, ATre, AQuattro, ACinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Auditorium Palladium</TextConcerto>
            <SecondTextConcerto>23/01/2016</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${FFFUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([FFFUno, FFFDue, FFFTre, FFFQuattro, FFFCinque, FFFSei]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>FAO</TextConcerto>
            <SecondTextConcerto>08/03/2016</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${LUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([LUno, LDue, LTre, LQuattro, LCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>LUISS </TextConcerto>
            <SecondTextConcerto>11/05/2016</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${FUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([FUno, FDue, FTre, FQuattro, FCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Farfa</TextConcerto>
            <SecondTextConcerto>24/09/2016</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${TUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([TUno, TDue, TTre, TQuattro, TCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Telethon</TextConcerto>
            <SecondTextConcerto>18/12/2016</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${SCUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([SCUno, SCDue, SCTre, SCQuattro, SCCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Santa Chiara</TextConcerto>
            <SecondTextConcerto>14/01/2017</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${AuUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([AuUno, AuDue, AuTre, AuQuattro, AuCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Un Peperoncino x Autismo</TextConcerto>
            <SecondTextConcerto>07/10/2017</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${PUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([PUno, PDue, PTre, PQuattro, PCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Il Ponte</TextConcerto>
            <SecondTextConcerto>28/10/2017</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${ComUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([ComUno, ComDue, ComTre, ComQuattro, ComCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Comunità Sant'Egidio</TextConcerto>
            <SecondTextConcerto>26/11/2017</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${PariUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([
              PariUno,
              PariDue,
              PariTre,
              PariQuattro,
              PariCinque,
              PariSei,
            ]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Teatro Parioli</TextConcerto>
            <SecondTextConcerto>19/12/2017</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${AppiaUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([AppiaUno, AppiaDue, AppiaTre]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Appia day</TextConcerto>
            <SecondTextConcerto>13/05/2018</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${PallaUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([
              PallaUno,
              PallaDue,
              PallaTre,
              PallaQuattro,
              PallaCinque,
              PallaSei,
            ]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Teatro Palladium </TextConcerto>
            <SecondTextConcerto>20/05/2018</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${PopoUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([PopoUno, PopoDue, PopoTre, PopoQuattro, PopoCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Museo Tradizioni Popolari</TextConcerto>
            <SecondTextConcerto>03/06/2018</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${ArUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([ArUno, ArDue, ArTre, ArQuattro, ArCinque]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Arco Club Parioli</TextConcerto>
            <SecondTextConcerto>09/02/2019</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${MonteUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([
              MonteUno,
              MonteDue,
              MonteTre,
              MonteQuattro,
              MonteCinque,
              MonteSei,
            ]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Montecitorio</TextConcerto>
            <SecondTextConcerto>12/04/2019</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
        <GalleryContainer
          style={{
            background: `linear-gradient(180deg,rgba(255,255,255,0.5),rgba(0,0,0,1)),url(${RavUno})`,
            backgroundSize: "cover",
          }}
          onClick={(e) => {
            setLista([RavUno, RavDue, RavTre, RavQuattro, RavCinque, RavSei]);
          }}
        >
          {" "}
          <ContainterConcertoDiv>
            <TextConcerto>Ravello Niemeyer</TextConcerto>
            <SecondTextConcerto>14/12/2019</SecondTextConcerto>
          </ContainterConcertoDiv>
        </GalleryContainer>
      </GridContainer>
      <RestaAggiornato />
      <ModalFoto open={open} setOpen={setOpen} imagesList={lista} />
    </ContainerGalleria>
  );
};

export default GalleriaFoto;
