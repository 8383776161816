import React from "react";
import {
  ContainerStoria,
  ContainerStoria2,
  ContainerText,
  StoryText,
  Title,
} from "./StoryStyled";
import ContinuaLeggere from "../../../assets/continualeggere.png";
import fotoinhome from "../../../assets/fotoinhome.jpg";
import { useMediaQuery } from "react-responsive";
const Story = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  return (
    <ContainerStoria2>
      <img
        src={fotoinhome}
        height={"460px"}
        width={isMobile ? "280px" : "300px"}
        alt={"fotoinhome"}
      />
      <ContainerText style={{ marginTop: "-3rem" }}>
        <Title>La nostra storia</Title>
        <StoryText
          style={{
            maxWidth: isMobile ? "400px" : "",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          L’associazione musicale "Il Grande Coro di Roma" (iGCR) è stata
          fondata nel 2015 da un gruppo di cantanti non professionisti
          provenienti da diverse realtà romane e del Lazio, uniti dalla passione
          per la musica ed il canto corale. Precedentemente alla sua fondazione,
          già dal 2010 il Coro si esprime in concerti che hanno come fine quello
          di sostenere, con il proprio impegno, iniziative di solidarietà e
          volontariato promuovendo la diffusione della musica polifonica-corale.
          Nel corso della sua storia l’iGCR ha riunito non soltanto cantanti dai
          vari CRAL di Roma (RAI, FAO, UNWFP, INAIL, TIM, INPS, MiBACT, Regione
          Lazio fra i principali) ma anche gruppi corali dei Castelli Romani e
          dipendenti dell’ESA (Agenzia Spaziale Europea) conferendo al coro,
          insieme ai dipendenti della FAO e del WFP, un tratto tipicamente
          internazionale.
        </StoryText>
        <a style={{ textDecoration: "none", color: "white" }} href={"/storia"}>
          <img
            width={"200px"}
            height={"auto"}
            src={ContinuaLeggere}
            alt={"continua leggere"}
          />
        </a>
      </ContainerText>
    </ContainerStoria2>
  );
};

export default Story;
