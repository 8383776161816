import React, { useEffect, useState } from "react";
import {
  ContainerConcert,
  ContainerConcerto,
  ContainerImmagine,
  ContainerText,
  ContainerTitle,
  TextConcerto,
  TitleConcerto,
} from "./ConcertiStyled";
import Locandina from "../../assets/locandina.png";
import ContinuaLeggere from "../../assets/continualeggere.png";
import {
  TextBenvenuti,
  TitleBenvenuti,
} from "../landing/home/benvenuti/BenvenutiStyle";
import {
  ContainerTotalText,
  TitleResta,
} from "../galleriafoto/GalleriaFotoStyled";
import News from "../../assets/news.png";
import ProssimiConcerti from "../../assets/Bottone, Prossimi concerti.png";
import ScopriDiPiu from "../../assets/scopridipiu.png";
import { TestoStoria } from "../storia/StoriaStyled";
import { createClient } from "@supabase/supabase-js";
const SUPABASE_URL = "https://arkmohlsxoogcmubsgwx.supabase.co";
const SUPBASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFya21vaGxzeG9vZ2NtdWJzZ3d4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjI1MDE3NDcsImV4cCI6MTk3ODA3Nzc0N30.aFubQavqChgWSWNAuFownNMALCwadRG3luha9dsHGe0";

const client = createClient(SUPABASE_URL, SUPBASE_ANON_KEY);

const Concerti = () => {
  const [lunghezza, setLunghezza] = useState<number>(0);
  const [conc, setConc] = useState<any>([]);
  const recuperaConcerto = async () => {
    const { data, error } = await client.from("prossimo").select();
    await setConc(data);
    console.log(conc.length);
  };

  useEffect(() => {
    recuperaConcerto();
  }, []);

  return (
    <ContainerConcert>
      <TitleBenvenuti>Prossimi Concerti </TitleBenvenuti>
      <TextBenvenuti>
        Le date e le locandine dei nostri prossimi concerti:
      </TextBenvenuti>
      {!!conc && (
        <ContainerConcerto>
          <ContainerImmagine>
            <img
              src={conc[conc.length - 1]?.indirizzo}
              alt={"locandina"}
              width={"100%"}
            />
          </ContainerImmagine>
          <ContainerText>
            <ContainerTitle>
              <TitleConcerto>{conc[conc.length - 1]?.titolo}</TitleConcerto>
              <TestoStoria>{conc[conc.length - 1]?.descrizione}</TestoStoria>
            </ContainerTitle>
            <a href={"https://Facebook.com/ilgrandecorodiroma"}>
              <img
                src={ScopriDiPiu}
                alt={"continua"}
                width={"150px"}
                style={{ position: "absolute", bottom: "1rem" }}
              />
            </a>
          </ContainerText>
        </ContainerConcerto>
      )}
    </ContainerConcert>
  );
};

export default Concerti;
