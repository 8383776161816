import React, { useState } from "react";
import {
  FirstContainerFooter,
  SecondContainerFooter,
  SubtitleFooter,
  TextFooter,
  TitleFooter,
} from "./FooterStyled";
import Up from "../../assets/up.png";
import Choir from "../../assets/choir.png";
const Footer = () => {
  const [attivo, setAttivo] = useState(false);

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setAttivo(true);
    } else {
      setAttivo(false);
    }
  }
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  return (
    <>
      <FirstContainerFooter>
        <TitleFooter>IL GRANDE CORO DI ROMA</TitleFooter>
        <a href="/chi-siamo">
          <img width={"70px"} src={Choir} alt="coro" />
        </a>
      </FirstContainerFooter>
      <SecondContainerFooter>
        <SubtitleFooter>
          {" "}
          Il Grande Coro di Roma, iGCR, Roma, C.F. 92032580588 © 2022, All
          rights reserved
        </SubtitleFooter>
        <div
          onClick={topFunction}
          style={{
            backgroundColor: "#eae9e1",
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            position: "fixed",
            bottom: "0.75rem",
            right: "3rem",
            display: attivo ? "flex" : "none",
            zIndex: 99,
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img width={"20px"} src={Up} alt={"up"} />
        </div>
      </SecondContainerFooter>
    </>
  );
};

export default Footer;
