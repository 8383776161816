import React, { useEffect, useState } from "react";
import {
  ContainerConcert,
  ContainerConcerto,
  ContainerImmagine,
  ContainerText,
  ContainerTitle,
  TextConcerto,
  TitleConcerto,
} from "../../concerti/ConcertiStyled";
import {
  TextBenvenuti,
  TitleBenvenuti,
} from "../home/benvenuti/BenvenutiStyle";
import ScopriDiPiu from "../../../assets/scopridipiu.png";
import uno from "../../../assets/concerti/1.jpg";
import due from "../../../assets/concerti/2.jpg";
import tre from "../../../assets/concerti/3.jpg";
import quattro from "../../../assets/concerti/4.jpg";
import cinque from "../../../assets/concerti/5.jpg";
import sei from "../../../assets/concerti/6.jpg";
import sette from "../../../assets/concerti/7.jpg";
import otto from "../../../assets/concerti/8.jpg";
import nove from "../../../assets/concerti/9.jpg";
import dieci from "../../../assets/concerti/10.jpg";
import undici from "../../../assets/concerti/11.jpg";
import dodici from "../../../assets/concerti/12.jpg";
import tredici from "../../../assets/concerti/13.jpg";
import quattordici from "../../../assets/concerti/14.jpg";
import quindici from "../../../assets/concerti/15.jpg";
import sedici from "../../../assets/concerti/16.jpg";
import diciasette from "../../../assets/concerti/17.jpg";
import diciotto from "../../../assets/concerti/18.jpg";
import diciannove from "../../../assets/concerti/19.jpg";
import venti from "../../../assets/concerti/20.jpg";
import ventuno from "../../../assets/concerti/21.jpg";
import ventidue from "../../../assets/concerti/22.jpg";
import ventitre from "../../../assets/concerti/23.jpg";
import ventiquattro from "../../../assets/concerti/24.jpg";
import venticinque from "../../../assets/concerti/25.jpg";
import ventisei from "../../../assets/concerti/26.jpg";
import ventisette from "../../../assets/concerti/27.jpg";
import ventotto from "../../../assets/concerti/28.jpg";
import ventinove from "../../../assets/concerti/29.jpg";
import trenta from "../../../assets/concerti/30.jpg";
import aliantea from "../../../assets/concerti/aliantea.jpg";
import trentuno from "../../../assets/concerti/31.jpg";
import trentadue from "../../../assets/concerti/32.jpg";
import trentatre from "../../../assets/concerti/33.jpg";
import trentaquattro from "../../../assets/concerti/34.jpg";
import trentacinque from "../../../assets/concerti/35.jpg";
import trentasei from "../../../assets/concerti/36.jpg";
import trentasette from "../../../assets/concerti/37.jpg";
import trentotto from "../../../assets/concerti/38.jpg";
import trentanove from "../../../assets/concerti/39.jpg";
import quaranta from "../../../assets/concerti/40.jpg";
import quarantuno from "../../../assets/concerti/41.jpg";
import quarantadue from "../../../assets/concerti/42.jpg";
import quarantatre from "../../../assets/concerti/43.jpg";
import quarantaquattro from "../../../assets/concerti/44.jpg";
import quarantacinque from "../../../assets/concerti/45.jpg";
import quarantasei from "../../../assets/concerti/46.jpg";
import quarantasette from "../../../assets/concerti/47.jpg";
import quarantotto from "../../../assets/concerti/48.jpg";
import quarantanove from "../../../assets/concerti/49.jpg";
import cinquanta from "../../../assets/concerti/50.jpg";
import cinquantuno from "../../../assets/concerti/51.jpg";
import cinquantadue from "../../../assets/concerti/52.jpg";
import cinquantatre from "../../../assets/concerti/53.jpg";
import cinquantaquattro from "../../../assets/concerti/54.jpg";
import cinquantacinque from "../../../assets/concerti/55.jpg";
import cinquantasei from "../../../assets/concerti/56.jpg";
import cinquantasette from "../../../assets/concerti/57.jpg";
import cinquantotto from "../../../assets/concerti/58.jpg";
import cinquantanove from "../../../assets/concerti/59.jpg";
import musicavita from "../../../assets/concerti/musicavita.jpg";
import locandina from "../../../assets/locandina.jpg";
import montecitorio from "../../../assets/concerti/montecitorio.jpg";
import inno from "../../../assets/concerti/inno.jpg";
import tutticuore from "../../../assets/concerti/tutticuore.jpg";
import { TestoStoria } from "../../storia/StoriaStyled";
import { NuovoLayout } from "./ArchivioConcertiStyled";
import { createClient } from "@supabase/supabase-js";

const SUPABASE_URL = "https://arkmohlsxoogcmubsgwx.supabase.co";
const SUPBASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFya21vaGxzeG9vZ2NtdWJzZ3d4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjI1MDE3NDcsImV4cCI6MTk3ODA3Nzc0N30.aFubQavqChgWSWNAuFownNMALCwadRG3luha9dsHGe0";

const client = createClient(SUPABASE_URL, SUPBASE_ANON_KEY);
const ArchivioConcerti = () => {
  const [nuoviConcerti, setNuoviConcerti] = useState<any>([]);
  const concerti = [
    {
      titolo: "UN-FAO Roma",
      descrizione:
        "Nel 2012 e 2013 in occasione delle celebrazioni del Natale e della giornata del World Food Day, il Coro si esibisce presso la UN-FAO a Roma.",
      foto: uno,
    },
    {
      titolo: "Musica per vita",
      descrizione:
        "Dicembre 2013, il Coro si esibisce all’Auditorium del Seraphicum per l’ABE onlus (Associazione per l Bambini Emato-Oncologici). L’evento Musica per Vita viene da SkyTV.",
      foto: musicavita,
    },
    {
      titolo: "Grottaferrata-Musicoterapia",
      descrizione: "",
      foto: tre,
    },
    {
      titolo: "Musical per la vita",
      descrizione:
        "Giugno 2014, il Coro si esibisce a Palazzo Farnese a Caprarola (VT) a favore dell’Ass. Peter Pan per l’accoglienza dei bambini onco-ematologici",
      foto: cinque,
    },
    {
      titolo: "Letture d'estate",
      descrizione: "",
      foto: sei,
    },
    {
      titolo: "Ospedal di San Giovanni Battista",
      descrizione:
        "Novembre 2014, l’iGCR tiene un concerto presso il Palazzo dell’Ospedale di San Giovanni Battista, su invito del Gran Priorato di Roma del Sovrano Ordine di San Giovanni di Gerusalemme di Rodi e di Malta (SMOM). Già nel 2013, il Coro si era esibito presso La Casa dei Cavalieri di Rodi e Malta ai Mercati Traianei a Roma.",
      foto: sette,
    },
    {
      titolo: "Auditorium",
      descrizione: "",
      foto: otto,
    },
    {
      titolo: "Ali di Antea",
      descrizione:
        "Dicembre 2014, il Coro tiene un concerto all’Oratorio del Gonfalone in occasione del Premio Ali di Antea onlus.",
      foto: aliantea,
      video: "https://youtu.be/WVk5Tpm3jDE",
    },
    {
      titolo: "Telethon",
      descrizione:
        "Dicembre 2014, L’iGCR viene invitato da RAI-1 a partecipare a Telethon Italia 2014 in diretta televisiva. L’evento è presentato da Fabrizio Frizzi.",
      foto: dieci,
    },
    {
      titolo: "Favola",
      descrizione: "",
      foto: undici,
    },
    {
      titolo: "Caritas Frascati",
      descrizione: "",
      foto: dodici,
    },
    {
      titolo: "Insieme Possiamo",
      descrizione: "",
      foto: tredici,
    },
    {
      titolo: "Concerto Bicentenario Don Bosco",
      descrizione: "",
      foto: quattordici,
    },
    {
      titolo: "Concerto di Beneficienza",
      descrizione: "",
      foto: quindici,
    },
    {
      titolo: "Sosteniamo FAVOLA",
      descrizione:
        "Febbraio 2015, l’iGCR tiene un concerto presso l’IRCCS su invito della Fondazione Santa Lucia a Roma a favore del nuovo laboratorio di neuro riabilitazione infantile FAVOLA.",
      foto: sedici,
    },
    {
      titolo: "Concerto Beneficenza",
      descrizione: "",
      foto: diciotto,
    },
    {
      titolo: "Spicchi LunaIncanto",
      descrizione:
        "Aprile 2015, l’iGCR partecipa all’evento Spicchi di Luna InCanto nella Sala Accademia del Conservatorio di S.Cecilia organizzato dall’Università Campus Bio-Medico di Roma (concerto ripreso ed andato in onda su RAI World-Italia e sul network nazionale RADIO RAI Classica). L’evento viene presentato da Pippo Baudo.",
      foto: diciannove,
    },
    {
      titolo: "Festa Europea della Musica",
      descrizione: "Festa Europea della Musica",

      foto: venti,
    },
    {
      titolo: "Fontanone Estate",
      descrizione:
        "Luglio 2015, l’iGCR partecipa su invito di Peter Pan onlus al Festival del Fontanone Estate a Roma.",
      foto: ventuno,
      video: "https://youtu.be/EEg1aochrWc",
    },
    {
      titolo: "Fontanone Classica",
      descrizione: "",
      foto: ventidue,
    },
    {
      titolo: "Concerto FAO",
      descrizione:
        "Settembre 2015, in occasione della  UNFAO Model Conference-Zero Hunger, l’IGCR viene invitato ad esibirsi in un concerto nella Plenary Hall della FAO a Roma. ",
      foto: ventiquattro,
    },
    {
      titolo: "Festa della Luce",
      descrizione: "",
      foto: venticinque,
    },
    {
      titolo: "Musica La Sapienza",
      descrizione:
        "Dicembre 2015, l’iGCR tiene un concerto nell’Aula Magna del Rettorato dell’Università La Sapienza di Roma per la S.A.N.E.S. Onlus.",
      foto: ventisei,
    },
    {
      titolo: "NATALE",
      descrizione: "",
      foto: ventisette,
    },
    {
      titolo: "Missionari Preziosissimo Sangue",
      descrizione: "",
      foto: ventotto,
    },
    {
      titolo: "Concerto per i detenuti",
      descrizione: "",
      foto: ventinove,
    },
    {
      titolo: "Tutti per un Cuore",
      descrizione:
        "Gennaio 2015, l’iGCR viene invitato ad esibirsi presso l’Auditorium del Parco della Musica (Sala Sinopoli) di Roma in occasione dell’iniziativa Gran Galà della Solidarietà (XIIa edizione) patrocinato dalla Onlus Tutti per un Cuore…un Cuore per Tutti, presentato da Fabrizio Frizzi.",
      foto: tutticuore,
    },
    {
      titolo: "The woman in red",
      descrizione:
        "Marzo 2016, l’IGCR si esibisce in occasione del UN Women Day promosso dalla UNWG, presso la Sheik Zayed Center della FAO.      ",
      foto: trentuno,
      video: "https://youtu.be/GpXdEQNHvvI",
    },
    {
      titolo: "Antea Associazione",
      descrizione:
        "Maggio 2016, l’Università LUISS Guido Carli invita l’IGCR ha tenere un concerto nell’Aula Magna a favore della Fondazione ANTEA, insieme al Coro polifonico delle Ragazze della LUISS.",
      foto: trentadue,
      video: "https://youtu.be/L_7FaEFlvFY",
    },
    {
      titolo: "Festa Europea della Musica",
      descrizione: "",
      foto: trentatre,
    },
    {
      titolo: "AmiciLazio",
      descrizione:
        "Settembre 2016, Abbazia di Santa Maria di Farfa, l’iGCR per AMICILazio onlus.",
      foto: trentaquattro,
    },
    {
      titolo: "Concerto per Haiti",
      descrizione: "",
      foto: trentacinque,
    },
    {
      titolo: "Telethon",
      descrizione:
        "Dicembre2016, l’iGCR viene invitato da RAI-1 a partecipare a Telethon Italia 2016  dove si esibisce in diretta televisiva presentata da Lorena Bianchetti, Ariana Ciampoli e Paolo Belli. ",
      foto: trentasei,
      video: "https://www.youtube.com/watch?v=y9sSGpyre-g",
    },
    {
      titolo: "Concerto Santa Chiara",
      descrizione: "",
      foto: trentasette,
    },
    {
      titolo: "Concerto Associazione malati SLA",
      descrizione: "",
      foto: trentotto,
    },
    {
      titolo: "Chiesa Valdese - Cavour",
      descrizione: "",
      foto: trentanove,
    },
    {
      titolo: "417sima fiera Nazionale",
      descrizione: "",
      foto: quaranta,
    },
    {
      titolo: "Non uno di meno",
      descrizione: "",
      foto: quarantuno,
    },
    {
      titolo: "Concerto Spagna",
      descrizione:
        "Giugno 2017 l’iGCR si reca in Spagna, su invito della Onlus A.D.A. España, dove il Coro tiene un concerto a Guadalajara nella Casa della Cultura.",
      foto: quarantadue,
    },
    {
      titolo: "Associazioni Umanitarie",
      descrizione: "",
      foto: quarantatre,
    },
    {
      titolo: "Un peperoncino per l'autismo",
      descrizione: "",
      foto: quarantaquattro,
    },
    {
      titolo: "Il Ponte",
      descrizione: "",
      foto: quarantacinque,
    },
    {
      titolo: "Insieme si può",
      descrizione:
        "Novembre 2017, l’iCGR viene invitato dalla Comunità di Sant’Egidio ad esibirsi in un concerto dal titolo Insieme si può,  presso il Complesso San Gallicano a Roma. L’evento promuove una raccolta fondi per i pranzi di Natale per i più bisognosi.",
      foto: quarantasei,
      video: "https://youtu.be/tZcnYoCgKKo",
    },
    {
      titolo: "I bambini ridono allo stesso modo",
      descrizione:
        "Dicembre 2017, l’IGCR ha tenuto un concerto al Teatro Parioli di Roma per la Paul Newman Dynamo Camp onlus.",
      foto: quarantasette,
    },
    {
      titolo: "Appia Day",
      descrizione: "",
      foto: quarantotto,
    },
    {
      titolo: "Regaliamo un sogno musicale",
      descrizione:
        "Maggio 2018, l’iGCR, tiene un concerto, Regaliamo un sogno musicale, presso il Teatro Palladium di Roma, su invito del Rotaract Palatino e sotto il patrocinio del Rotary. ",
      foto: quarantanove,
      video: "https://youtu.be/wL9gjQoNdZQ",
    },
    {
      titolo: "Concerto",
      descrizione: "",
      foto: cinquanta,
    },
    {
      titolo: "Anniversario Appia Antica",
      descrizione:
        "Novembre 2018, l’iGCR, su invito del Comitato dell’ Appia Day, si esibisce in un concerto presso l’Ex Cartiera Latina, in occasione del 30° Anniversario del Parco Regionale dell’Appia Antica a Roma. Già nel maggio 2018, il Coro partecipa all’evento annuale organizzato dall’Appia Day nel Complesso monumentale di Cecilia Metella. ",
      foto: cinquantuno,
    },
    {
      titolo: "Tennis Club Parioli",
      descrizione: "",
      foto: cinquantadue,
    },
    {
      titolo: "Inno d'Italia",
      descrizione:
        "Maggio 2015,  in occasione delle celebrazioni del Quirinale per la Festa del Lavoro,  l’iGCR si è esibito con l'Inno nazionale italiano alla presenza del Presidente della Repubblica italiana, Sergio Mattarella (evento ripreso da RAI News e SKYNews24h). ",
      foto: inno,
    },
    {
      titolo: "419sima fiera nazionale",
      descrizione: "",
      foto: cinquantatre,
    },
    {
      titolo: "Ladies in Blue",
      descrizione: "",
      foto: cinquantaquattro,
    },

    {
      titolo: "Associazione Dynamo Camp",
      descrizione: "",
      foto: cinquantasei,
    },
    {
      titolo: "Ringraziamenti Ordine di Malta",
      descrizione: "",
      foto: cinquantasette,
    },
    {
      titolo: "Montecitorio Associazione USA-ITALIA",
      descrizione:
        "Aprile 2019, su invito della Fondazione Italia-USA, l’IGC ha tenuto un concerto dal titolo Note d’America a Palazzo Montecitorio a Roma",
      foto: montecitorio,
      video: "https://youtu.be/h1kLKjwnLCI",
    },
    {
      titolo: "Ringraziamenti Comunità Sant'Egidio",
      descrizione: "",
      foto: cinquantanove,
    },
    {
      titolo: "Appia Day",
      descrizione:
        "        Ottobre 2021, l’IGCR in concerto alla Casina del Cardinal Bessarione invitata dal Comitato Appia Day.      ",
      foto: cinquantacinque,
    },
  ];

  const recuperaConcerti = async () => {
    const { data, error } = await client.from("concerti").select();
    setNuoviConcerti(data);
  };
  useEffect(() => {
    recuperaConcerti();
  }, []);

  return (
    <ContainerConcert>
      <TitleBenvenuti>Archivio Concerti </TitleBenvenuti>
      <TextBenvenuti>
        Le locandine dei concerti passati raccontano gli eventi e le esibizioni
        del nostro coro:
      </TextBenvenuti>
      {nuoviConcerti &&
        nuoviConcerti!.map((concerto: any, i: any) => {
          return (
            <ContainerConcerto>
              <ContainerImmagine>
                <img
                  src={concerto.indirizzo}
                  alt={"locandina"}
                  width={"100%"}
                />
              </ContainerImmagine>
              <ContainerText>
                <ContainerTitle>
                  <TitleConcerto>{concerto.titolo}</TitleConcerto>
                  <TestoStoria>{concerto.descrizione}</TestoStoria>
                </ContainerTitle>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "absolute",
                    bottom: "1rem",
                    gap: "1rem",
                  }}
                >
                  <NuovoLayout>
                    <a
                      href={"https://Facebook.com/ilgrandecorodiroma"}
                      target="_blank"
                    >
                      <img src={ScopriDiPiu} alt={"continua"} width={"130px"} />
                    </a>

                    {typeof concerto.video !== "undefined" && (
                      <a href={concerto.video} target="_blank">
                        <button
                          style={{
                            borderRadius: "50px",
                            border: "none",
                            backgroundColor: "#bc4749",
                            color: "white",
                            width: "130px",
                            height: "30px",
                          }}
                        >
                          Video
                        </button>
                      </a>
                    )}
                  </NuovoLayout>
                </div>
              </ContainerText>
            </ContainerConcerto>
          );
        })}
      {concerti
        .map((concerto, i) => {
          return (
            <ContainerConcerto>
              <ContainerImmagine>
                <img src={concerto.foto} alt={"locandina"} width={"100%"} />
              </ContainerImmagine>
              <ContainerText>
                <ContainerTitle>
                  <TitleConcerto>{concerto.titolo}</TitleConcerto>
                  <TestoStoria>{concerto.descrizione}</TestoStoria>
                </ContainerTitle>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    position: "absolute",
                    bottom: "1rem",
                    gap: "1rem",
                  }}
                >
                  <NuovoLayout>
                    <a
                      href={"https://Facebook.com/ilgrandecorodiroma"}
                      target="_blank"
                    >
                      <img src={ScopriDiPiu} alt={"continua"} width={"130px"} />
                    </a>

                    {typeof concerto.video !== "undefined" && (
                      <a href={concerto.video} target="_blank">
                        <button
                          style={{
                            borderRadius: "50px",
                            border: "none",
                            backgroundColor: "#bc4749",
                            color: "white",
                            width: "130px",
                            height: "30px",
                          }}
                        >
                          Video
                        </button>
                      </a>
                    )}
                  </NuovoLayout>
                </div>
              </ContainerText>
            </ContainerConcerto>
          );
        })
        .reverse()}
    </ContainerConcert>
  );
};
export default ArchivioConcerti;
