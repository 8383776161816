import React, { useMemo, useState , useEffect} from "react";
import { ContainerContatti } from "./ContattiStyled";
import { useMediaQuery } from "react-responsive";

import emailjs from "emailjs-com";
import {
  ContainerConcert,
  ContainerConcert2,
} from "../concerti/ConcertiStyled";
import { TextConcerto } from "../galleriafoto/GalleriaFotoStyled";
import { TitleBenvenuti } from "../landing/home/benvenuti/BenvenutiStyle";
import { TestoStoria } from "../storia/StoriaStyled";
import feniarco from "../../assets/feniarco.png";
import arcl from "../../assets/arcl.png";

const Contatti = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [messaggio, setMessaggio] = useState("");
  const [email, setEmail] = useState("");
  const [risposta, setRisposta] = useState("Informazioni");
  const [invio, setInvio] = useState(false);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [])
  const abilitato = useMemo(() => {
    return (
      nome !== "" &&
      cognome !== "" &&
      messaggio !== "" &&
      email !== "" &&
      risposta !== "Informazioni"
    );
  }, [cognome, email, messaggio, nome, risposta]);
  const saveRequest = () => {
    if (!abilitato) {
      return null;
    }
    emailjs
      .send(
        "service_ybdcc6n",
        "template_c5bv7nh",
        {
          nome: nome,
          cognome: cognome,
          email: email,
          risposta: risposta,
          messaggio: messaggio,
        },
        "a2PrZzW0LqO0Trvip"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    setNome("");
    setCognome("");
    setEmail("");
    setRisposta("");
    setMessaggio("");
    setInvio(true);
  };

  return (
    <>
      {isMobile && (
        <ContainerContatti>
          <TitleBenvenuti
            style={{
              textAlign: "center",
              marginTop: "2rem",
              marginBottom: "4rem",
            }}
          >
            Contatti
          </TitleBenvenuti>
          <div>
            <TitleBenvenuti
              style={{
                fontSize: "22px",
              }}
            >
              Dove contattarci?
            </TitleBenvenuti>
            <TestoStoria
              style={{
                color: "black",
              }}
            >
              - email :{" "}
              <span style={{ color: "#bc4749" }}>
                <a
                  href="mailto:il.grande.coro@gmail.com"
                  style={{ textDecoration: "none", color: "#bc4749" }}
                >
                  il.grande.coro@gmail.com
                </a>
              </span>
              <br />- website :{" "}
              <span style={{ color: "#bc4749" }}>
                <a
                  href="http://ilgrandecorodiroma.com"
                  style={{ textDecoration: "none", color: "#bc4749" }}
                >
                  ilgrandecorodiroma.com
                </a>
              </span>
              <br />- facebook :{" "}
              <span style={{ color: "#bc4749" }}>
                <a
                  style={{ textDecoration: "none", color: "#bc4749" }}
                  href="https://www.facebook.com/ilgrandecorodiroma"
                >
                  /ilgrandecorodiroma
                </a>
              </span>
              <br />- youtube :{" "}
              <span style={{ color: "#bc4749" }}>
                <a
                  style={{ textDecoration: "none", color: "#bc4749" }}
                  href="https://www.youtube.com/channel/UCecpJXZCCNSO6-VK6W4cLTA"
                >
                  ilgrandecorodiroma
                </a>
              </span>
              <br />
            </TestoStoria>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: isMobile ? "4rem" : "10rem",
              gap: "5rem",
            }}
          >
            <div>
              <TitleBenvenuti
                style={{ fontSize: "22px", marginLeft: "0.5rem" }}
              >
                Sede delle prove
              </TitleBenvenuti>
              <TestoStoria
                style={{
                  width: "100%",
                  marginBottom: "2rem",
                  marginLeft: "0.50rem",
                }}
              >
                Indirizzo:
                <br />
                <span style={{ fontStyle: "italic" }}>
                  Chiesa del centro regionale Sant'Alessio
                  <br />
                  Margherita di Savoia per i ciechi,
                  <br />
                  Via Carlo Tommaso Odescalchi, 38, Roma
                  <br />{" "}
                </span>
                <br />
                Giorno di prove :<br />{" "}
                <span style={{ fontStyle: "italic" }}>
                  Mercoledi ore 18.30 - 20.30
                </span>
              </TestoStoria>
            </div>
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  width="300"
                  height="500"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=%20%20%20%20%20%20%20%20%20%20Via%20Carlo%20Tommaso%20Odescalchi,%2038,%20Roma&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
                <style>.gmap_canvas </style>
              </div>
            </div>
          </div>
          <div>
            <TitleBenvenuti
              style={{
                fontSize: "22px",
                marginTop: "4rem",
              }}
            >
              L'associazione
            </TitleBenvenuti>

            <TestoStoria>
              Anno di fondazione :{" "}
              <span style={{ fontStyle: "italic" }}>2015</span>
              <br />
              Statuto :{" "}
              <span style={{ color: "red" }}>
                <a
                  style={{ textDecoration: "none", color: "#bc4749" }}
                  href="/statuto.pdf"
                  target={"_blank"}
                >
                  clicca qui per scaricarlo
                </a>
              </span>
              <br />
              <br />
              Il grande coro è associato:
            </TestoStoria>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "4rem",
              justifyContent: "space-evenly",
              marginTop: "5rem",
            }}
          >
            <a href="https://www.italiacori.it/grande-coro-roma">
              <img src={feniarco} width={"300px"}></img>
            </a>

            <a href="https://www.italiacori.it/grande-coro-roma">
              <img src={arcl} width={"300px"} />
            </a>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TitleBenvenuti
              style={{
                fontSize: "22px",
                marginTop: "10rem",
              }}
            >
              SCRIVICI
            </TitleBenvenuti>

            <div
              id="form"
              style={{
                width: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                padding: "4rem",
              }}
            >
              <input
                name="nome"
                placeholder="Nome"
                style={{ height: "30px" }}
                onChange={(x) => setNome(x.target.value)}
              />
              <input
                name="cognome"
                placeholder="Cognome"
                style={{ height: "30px" }}
                onChange={(x) => setCognome(x.target.value)}
              />
              <input
                name="email"
                placeholder="Email"
                style={{ height: "30px" }}
                onChange={(x) => setEmail(x.target.value)}
              />
              <select
                name="scelta"
                style={{ height: "30px" }}
                onChange={(x) => setRisposta(x.target.value)}
              >
                <option value="informazioni sul coro">
                  Vorrei informazioni sul coro
                </option>
                <option value="entrare nel coro">
                  Vorrei entrare nel coro
                </option>
              </select>
              <textarea
                style={{ height: "100px" }}
                rows={3}
                name="message"
                placeholder="Messaggio (opzionale) "
                onChange={(x) => setMessaggio(x.target.value)}
              />

              <button
                onClick={() => saveRequest()}
                disabled={true}
                style={{
                  backgroundColor: "#bc4749",
                  border: "none",
                  height: "30px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "18px",
                }}
              >
                <p style={{ color: "white", fontWeight: "bold" }}>INVIA</p>
              </button>
              {invio && (
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Richiesta Inviata
                </p>
              )}
            </div>
          </div>
        </ContainerContatti>
      )}
      {!isMobile && (
        <ContainerContatti>
          <TitleBenvenuti
            style={{
              textAlign: "center",
              marginTop: "2rem",
              marginBottom: "4rem",
            }}
          >
            Contatti
          </TitleBenvenuti>
          <div>
            <TitleBenvenuti
              style={{
                fontSize: "22px",
                marginLeft: "-32rem",
              }}
            >
              Dove contattarci?
            </TitleBenvenuti>
            <TestoStoria
              style={{
                color: "black",
                marginLeft: "-32rem",
              }}
            >
              - email :{" "}
              <span style={{ color: "#bc4749" }}>
                <a
                  href="mailto:il.grande.coro@gmail.com"
                  style={{ textDecoration: "none", color: "#bc4749" }}
                >
                  il.grande.coro@gmail.com
                </a>
              </span>
              <br />- website :{" "}
              <span style={{ color: "#bc4749" }}>
                <a
                  href="http://ilgrandecorodiroma.com"
                  style={{ textDecoration: "none", color: "#bc4749" }}
                >
                  ilgrandecorodiroma.com
                </a>
              </span>
              <br />- facebook :{" "}
              <span style={{ color: "#bc4749" }}>
                <a
                  style={{ textDecoration: "none", color: "#bc4749" }}
                  href="https://www.facebook.com/ilgrandecorodiroma"
                >
                  /ilgrandecorodiroma
                </a>
              </span>
              <br />- youtube :{" "}
              <span style={{ color: "#bc4749" }}>
                <a
                  style={{ textDecoration: "none", color: "#bc4749" }}
                  href="https://www.youtube.com/channel/UCecpJXZCCNSO6-VK6W4cLTA"
                >
                  ilgrandecorodiroma
                </a>
              </span>
              <br />
            </TestoStoria>
          </div>
          <div
            style={{
              borderBottom: "1px solid grey",
              width: "80%",
              position: "absolute",
              top: "26rem",
            }}
          />
          <div
            style={{
              borderBottom: "1px solid grey",
              width: "80%",
              position: "absolute",
              top: "69rem",
            }}
          />
          <div
            style={{
              borderBottom: "1px solid grey",
              width: "80%",
              position: "absolute",
              top: "100rem",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "10rem",
              gap: "5rem",
            }}
          >
            <div>
              <TitleBenvenuti
                style={{ fontSize: "22px", marginLeft: "0.5rem" }}
              >
                Sede delle prove
              </TitleBenvenuti>
              <TestoStoria
                style={{
                  width: "100%",
                  marginBottom: "30rem",
                  marginLeft: "0.50rem",
                }}
              >
                Indirizzo:
                <br />
                <span style={{ fontStyle: "italic" }}>
                  Chiesa del centro regionale Sant'Alessio
                  <br />
                  Margherita di Savoia per i ciechi,
                  <br />
                  Via Carlo Tommaso Odescalchi, 38, Roma
                  <br />{" "}
                </span>
                <br />
                Giorno di prove :<br />{" "}
                <span style={{ fontStyle: "italic" }}>
                  Mercoledi ore 18.30 - 20.30
                </span>
              </TestoStoria>
            </div>
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  width="600"
                  height="500"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=%20%20%20%20%20%20%20%20%20%20Via%20Carlo%20Tommaso%20Odescalchi,%2038,%20Roma&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
                <style>.gmap_canvas </style>
              </div>
            </div>
          </div>
          <div>
            <TitleBenvenuti
              style={{
                fontSize: "22px",
                marginLeft: "-32rem",
              }}
            >
              L'associazione
            </TitleBenvenuti>

            <TestoStoria style={{ marginLeft: "-32rem" }}>
              Anno di fondazione :{" "}
              <span style={{ fontStyle: "italic" }}>2015</span>
              <br />
              Statuto :{" "}
              <span style={{ color: "red" }}>
                <a
                  style={{ textDecoration: "none", color: "#bc4749" }}
                  href="/statuto.pdf"
                  target={"_blank"}
                >
                  clicca qui per scaricarlo
                </a>
              </span>
              <br />
              <br />
              Il grande coro è associato:
            </TestoStoria>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4rem",
              justifyContent: "space-evenly",
              marginTop: "5rem",
            }}
          >
            <a href="https://www.italiacori.it/ilgrande-coro-roma">
              <img src={feniarco} width={"300px"}></img>
            </a>

            <a href="https://www.italiacori.it/ilgrande-coro-roma">
              <img src={arcl} width={"300px"} />
            </a>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TitleBenvenuti
              style={{
                fontSize: "22px",
                marginTop: "10rem",
              }}
            >
              SCRIVICI
            </TitleBenvenuti>

            <div
              style={{
                width: "800px",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                padding: "4rem",
              }}
            >
              <input
                name="nome"
                placeholder="Nome"
                style={{ height: "30px" }}
                onChange={(x) => setNome(x.target.value)}
              />
              <input
                name="cognome"
                placeholder="Cognome"
                style={{ height: "30px" }}
                onChange={(x) => setCognome(x.target.value)}
              />
              <input
                name="email"
                placeholder="Email"
                style={{ height: "30px" }}
                onChange={(x) => setEmail(x.target.value)}
              />
              <select
                name="scelta"
                style={{ height: "30px" }}
                onChange={(x) => setRisposta(x.target.value)}
              >
                <option value="informazioni sul coro">
                  Vorrei informazioni sul coro
                </option>
                <option value="entrare nel coro">
                  Vorrei entrare nel coro
                </option>
              </select>
              <textarea
                style={{ height: "100px" }}
                rows={3}
                name="message"
                placeholder="Messaggio (opzionale) "
                onChange={(x) => setMessaggio(x.target.value)}
              />

              <button
                onClick={() => saveRequest()}
                style={{
                  backgroundColor: "#bc4749",
                  border: "none",
                  height: "30px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "18px",
                }}
              >
                <p style={{ color: "white", fontWeight: "bold" }}>INVIA</p>
              </button>
              {invio && (
                <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Richiesta Inviata
                </p>
              )}
            </div>
          </div>
        </ContainerContatti>
      )}
    </>
  );
};

export default Contatti;
