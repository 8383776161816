import React from "react";
import { Home } from "./home";
import { Benvenuti } from "./home/benvenuti";
import { Story } from "./story";
import { RestaAggiornato } from "./restaaggiornato";
import { Footer } from "../footer";
import { LandingContainer } from "./LandingStyle";
import BottonFB from "../../assets/BottonFB.png";
import { useMediaQuery } from "react-responsive";

const Landing = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  return (
    <LandingContainer>
      <Home />
      <a
        href="https://www.facebook.com/ilgrandecorodiroma"
        target={"_blank"}
        style={{
          zIndex: 100000,
          position: "absolute",
          bottom: isMobile ? "142rem" : "80rem",
          opacity: 1,
        }}
      >
        <img
          src={BottonFB}
          alt={"button"}
          width={isMobile ? "100px" : "200px"}
        />
      </a>
      <div
        style={{
          width: isMobile ? "100%" : "800px",
          gap: "3rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Benvenuti />
        <Story />
        <RestaAggiornato />
      </div>
    </LandingContainer>
  );
};

export default Landing;
